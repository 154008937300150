import Head from 'next/head';
import { NextComponentType } from 'next';
import { useTranslation } from 'react-i18next';

import { AIRBRAKE_ENVIRONMENT, AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY } from '~/lib/constants';
import ErrorState from '~/components/ErrorState/ErrorState';

/**
 * Server error page (500 etc.)
 */
const Error: NextComponentType = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <Head>
        <title>{t('common:PageNotFound.title')}</title>
      </Head>
      <ErrorState
        fullScreenHeight
        image="/img/error-pic-1.png"
        title={t('common:ServerError.error-title')}
        subtitle={t('common:ServerError.error-subtitle')}
        imageHeight={212}
        imageWidth={330}
      />
    </>
  );
};

Error.getInitialProps = ({ res, err, asPath }) => {
  if (
    typeof window === 'undefined'
    && AIRBRAKE_ENVIRONMENT
    && AIRBRAKE_PROJECT_ID
    && AIRBRAKE_PROJECT_KEY
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
    const Airbrake = require('@airbrake/node');
    const notifier = new Airbrake.Notifier({
      projectId: parseInt(AIRBRAKE_PROJECT_ID, 10),
      projectKey: AIRBRAKE_PROJECT_KEY,
      environment: AIRBRAKE_ENVIRONMENT,
    });

    if (err) {
      notifier.notify({
        error: err,
        context: {
          route: asPath,
        },
      });
    }
  }

  let statusCode: number | undefined;

  if (res) {
    statusCode = res.statusCode;
  } else {
    statusCode = err ? err.statusCode : 404;
  }

  return { statusCode };
};

export default Error;
